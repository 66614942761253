import React, { useContext, useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { BasicData } from '@App';
import { getTextColorClass, scrollTo } from '@utils/Utils';

import './style.scss';

const NavBarBottom = ({ menuOpen, toggleMenu }) => {
    const { branding, unique_enquiry_url, theme } = useContext(BasicData);

    const bookNow = useCallback(() => {
        if (unique_enquiry_url?.length || branding?.enquiry_url?.length) {
            unique_enquiry_url ?  window.open(unique_enquiry_url, '_blank') : window.open(branding.enquiry_url, '_blank')
        } else {
            scrollTo("enquiry-form");
        }
    }, [branding, unique_enquiry_url])

    const showEnquireButton = useMemo(() => {
        if (theme?.enquiry_button_label === "Hidden") return false;
        return true;
    }, [theme])

    return (
        <div className={`nav-bar-bottom`} id='nav-bar-bottom'>
            <h6 className="btn-menu" onClick={toggleMenu}>
                <i className={`icon-${menuOpen ? 'x' : 'mobile-menu'}`}></i>
                <Trans>Menu</Trans>
            </h6>
            {showEnquireButton && (
                <button className={`btn ${getTextColorClass(theme?.button_color)}`} style={{ backgroundColor: `#${theme?.button_color}` }} onClick={bookNow}>
                    <h6><Trans id={theme?.enquiry_button_label || "Book Now"} values={theme?.enquiry_button_label || "Book Now"} /></h6>
                </button>
            )}
        </div>
    )
}

export default React.memo(NavBarBottom);