import React, { useContext, useMemo } from 'react';
import { BasicData } from '@App';
import { safeImageUrl, getColor } from '@utils/Utils';

import './style.scss';

const NavBarTop = () => {
    const { branding, theme } = useContext(BasicData);
    const colors = getColor(theme);

    const hideLogo = useMemo(() => theme?.logo_size === 'Hide', [theme])

    const logoSize = useMemo(() => {
        return {
            'Small': { width: '150', height: '75' },
            'Medium': { width: '200', height: '100' },
            'Large': { width: '300', height: '150' }
        }[theme?.logo_size] || { width: '150', height: '75' }
    }, [theme])

    const logo = useMemo(() => {
        return branding?.logo_url_fragment ? safeImageUrl(branding.logo_url_fragment, logoSize.width, logoSize.height, "", "?fmt=png") : null;
    }, [branding, logoSize])

    if (hideLogo) {
        return <></>
    }

    return (
        <div
            className={`nav-bar-top ${branding?.is_cobranded ? "co-branded" : ""} ${theme?.logo_size ? theme.logo_size.toLowerCase() : 'small'}`}
            id='nav-bar-top'
            style={{
                backgroundColor: theme?.navigation_bar_color
                    ? `#${theme.navigation_bar_color}`
                    : `#${colors?.secondary}`
            }}
        >
            {logo && (
                <a href={branding?.website}>
                    <img
                        src={logo}
                        alt=""
                        width={logoSize.width}
                        height={logoSize.height}
                    />
                </a>
            )}
        </div>
    )
}

export default React.memo(NavBarTop);