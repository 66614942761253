import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import SliderComponent from '@components/slider'
import VideoComponent from '@components/video';
import { Trans } from '@lingui/macro';
import { BasicData } from '@App';
import { getSliderImages } from '@utils/Utils';

import './style.scss';

const CoverPage = ({ itineraryDisabled }) => {
    const { name, client_name, days, destinations, trip_summary, cover_image_url_fragments, video_embed_link, media_type, theme, branding } = useContext(BasicData);

    const destinationLegs = trip_summary?.legs?.filter(x => x.destination_id || x.stops);
    const destinationIds = Array.from(new Set(destinationLegs?.map(x => x.destination_id || x.stops.map(y => y.destination_id)).flat()));
    const destinationNames = destinationIds.map(x => destinations[x]?.name);
    const showVideo = media_type === 'video' && video_embed_link;
    const coverImages = getSliderImages(cover_image_url_fragments);

    return (
        <div className='cover-page' id="home" style={{filter: `grayscale(${itineraryDisabled ? '100%' : '0%'})`}}>
            <Helmet>
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="og:title" content={name} />
                <meta name="twitter:title" content={name} />
                <meta property="og:description" content={`${days - 1} Night trip prepared by ${branding["company_name"]}`} />
                <meta name="twitter:description" content={`${days - 1} Night trip prepared by ${branding["company_name"]}`} />
                <meta name="description" content={name} />
                <meta name="title" content={`${days - 1} Night trip prepared by ${branding["company_name"]}`} />
            </Helmet>
            {!showVideo && <SliderComponent images={coverImages} sizes='(max-width: 1920px) 100vw, 1920px' loadPrevNext={true} autoPlay={theme?.display_slideshow} />}
            {showVideo && <VideoComponent url={video_embed_link} autoPlay loop />}
            <div className='text'>
                {!itineraryDisabled && <h4>{client_name}</h4>}
                <h1>{name}</h1>
                <h4>{days} {days === 1 ? <Trans>Day</Trans> : <Trans>Days</Trans>} / {days - 1} {(days - 1) === 1 ? <Trans>Night</Trans> : <Trans>Nights</Trans>}</h4>
                <ul>
                    {destinationNames.map((destinationName, i) => (
                        <li key={i}><h4>{i !== 0 ? <i>•</i> : ''}{destinationName}</h4></li>
                    ))}
                </ul>
                {
                    itineraryDisabled &&
                    <span className='disabled-section'>
                        <i className='icon-warning-icon'></i>
                        <Trans>This Itinerary is disabled. Please contact the owner to enable it.</Trans>
                    </span>
                }
            </div>
            <i className="icon-arrow-down"></i>
        </div>
    )
}

export default CoverPage