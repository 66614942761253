import React, { lazy, Suspense, useEffect, useState, createContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import CoverPage from '@components/cover-page';
import ErrorPage from '@components/error-page';
import NavBarComponent from '@components/nav-bar';
import Contact from '@components/contact';
import BackToTop from '@components/back-to-top';
import SpinnerComponent from '@components/spinner';
import TripSummaryPage from '@components/trip-summary';
import BodyContainer from '@containers/body';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { getBasisData, trackView } from '@services/itinerary';
import { dynamicActivate } from '@utils/i18n';

import { getItineraryIdentifier, shouldBeTracking, applyTheme, shouldbeBranded, getSliderImages, safeImageUrl } from '@utils/Utils';
import './App.scss';
import './assets/fonts/wetu/wetu-font-icons.scss';

export const BasicData = createContext();

const EnquiryForm = lazy(() => import('@components/enquiry-form'));
const FooterComponent = lazy(() => import('@components/footer'));
const TermsAndConditionsComponent = lazy(() => import('@components/terms-and-conditions'));
const TravelGuidanceComponent = lazy(() => import ('@components/travel-guidance'));

function App() {
  const [basicData, setBasicData] = useState(null);
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);

  useEffect(() => {
    const itineraryIdentifier = getItineraryIdentifier();

    if (itineraryIdentifier) {
      getBasisData(itineraryIdentifier, shouldbeBranded()).then(basicData => {

        setBasicData(basicData);

        if (basicData.forbidden || basicData.disabled || basicData.not_found)
          return;

        document.title = basicData.name;

        dynamicActivate(basicData.language);

        if (shouldbeBranded()) {
          applyTheme(basicData?.theme, basicData?.branding)
        }

        if (shouldBeTracking())
          trackView(itineraryIdentifier);
      })
    }
  }, [])

  const firstImage = useMemo(() => {
    if (basicData && !(basicData.media_type === "video" && basicData.video_embed_link)) {
      if (basicData.cover_image_url_fragments && basicData.cover_image_url_fragments.length > 0) {
        return getSliderImages(basicData.cover_image_url_fragments)[0];
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }, [basicData])

  if (!basicData) {
    return (
      <div className="app">
        <SpinnerComponent loadingScreen />
      </div>
    );
  } else {
    return (
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        <BasicData.Provider value={basicData || {}}>
          {
            ((basicData.not_found || basicData.forbidden) && <ErrorPage />) ||
            (basicData.disabled && <CoverPage itineraryDisabled={true} />) ||
            <div className="app">
              <Helmet>
                <meta property="og:title" content="wetu.com" />
              </Helmet>
              {firstImage && 
                <img 
                  style={{display: "none"}} 
                  fetchpriority="high" 
                  alt="" 
                  sizes="(max-width: 1920px) 100vw, 1920px"
                  onLoad={() => { setFirstImageLoaded(true); }}
                  onError={() => { setFirstImageLoaded(true); }}
                  src={safeImageUrl(firstImage.UrlFragment, firstImage.Default.Width, firstImage.Default.Height, firstImage.Default.Mode, firstImage.Default.QueryString)}
                  srcSet={`${firstImage.Alternates.map(x => `${safeImageUrl(firstImage.UrlFragment, x.Width, x.Height, x.Mode, x.QueryString)} ${x.Width}w`).join(', ')}, ${safeImageUrl(firstImage.UrlFragment, firstImage.Default.Width, firstImage.Default.Height, firstImage.Default.Mode, firstImage.Default.QueryString)} ${firstImage.Default.Width}w`}
                />
              }
              {firstImage && !firstImageLoaded ?
                <div className="app">
                  <SpinnerComponent loadingScreen />
                </div>
                :
                <>
                  <CoverPage itineraryDisabled={false} />
                  <NavBarComponent />
                 
                  <TripSummaryPage />
                  <BodyContainer />

                  <Suspense fallback={<SpinnerComponent />}>
                    <EnquiryForm />
                  </Suspense>
                  {(basicData?.branding?.travel_guidance) &&
                    <Suspense fallback={<SpinnerComponent />}>
                    <TravelGuidanceComponent />
                  </Suspense>
                  }
                  {(basicData?.terms_and_conditions || basicData?.branding?.terms_and_conditions) &&
                    <Suspense fallback={<SpinnerComponent />}>
                      <TermsAndConditionsComponent />
                    </Suspense>
                  }
                  <Suspense fallback={<SpinnerComponent />}>
                    <FooterComponent />
                  </Suspense>

                  <div className='floating-elements'>
                    {basicData?.branding &&
                      <Contact />
                    }
                    <BackToTop />
                  </div>
                </>
              }
            </div>
          }
        </BasicData.Provider>
      </I18nProvider>
    );
  }
}

export default App;
