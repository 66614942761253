import LottiePlayer from 'react-lottie-player';
import loadingAnimation from '@assets/json/enquire-loading.json';

import './style.scss';

const SpinnerComponent = ({ loadingScreen }) => {
    return (
        <div className="spinner-wrap">
            {loadingScreen ? (
                <LottiePlayer 
                    animationData={loadingAnimation}
                    play
                    style={{
                        width: '100%',
                        maxWidth: '400px'
                    }}
                />
            ) : (
                <div className="spinner"></div>
            )}
        </div>
    )
}

export default SpinnerComponent