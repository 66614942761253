import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide  } from 'swiper/react';
import { Autoplay, EffectFade, Lazy } from 'swiper';
import { Tooltip } from 'react-tooltip';
import { safeImageUrl, isMobile } from '@utils/Utils';
import SliderThumbs from './slider-thumbs';
import SlideUp from '../slide-up';

import './style.scss';
import 'swiper/css';
import 'swiper/css/lazy';
import "swiper/css/effect-fade";


const SliderComponent = ({ className = '', images = [], sizes = '100vw', withNavigation = false, loadPrevNext = false, thumbsPosition="top", autoPlay = false, nested = false }) => {
    const swiper = useRef();
    const showNavigation = withNavigation && images.length > 1;

    const [active, setActive] = useState(0);
    const [showInfo, setShowInfo] = useState(null)
    const mobile = isMobile()

    const onSlideChange = useCallback(s => {
        if (withNavigation) {
            setActive(s.realIndex);
        }
    }, [withNavigation])

    useEffect(() => {
        if (swiper.current) {
            swiper.current.slideToLoop(active);
        }
    }, [active, swiper])

    const isLongDescription = useCallback((parentId, elementId, creditId) => {
        if (mobile) {
            return false
        }
        const parentComponent = document.getElementById(parentId);
        const childElement = document.getElementById(elementId);
        const creditElement = document.getElementById(creditId);
        if (!parentComponent || !childElement) {
            return false
        }
        const parentWidth = parentComponent.offsetWidth;
        const childWidth = childElement.offsetWidth;
        let creditWidth = 0;
    
        if (creditElement) {
            creditWidth = creditElement.offsetWidth;
        }
        if (creditWidth > 0) {
            childElement.style.maxWidth = `${parentWidth - creditWidth - 100}px`
            return ((childWidth + creditWidth) / parentWidth) * 100 >= 90
        }

        return (childWidth / parentWidth) * 100 >= 95

    }, [mobile])

    const handleInfoClick = useCallback((itemLabel, itemDescription) => {
        if (!mobile) {
            return
        }
        setShowInfo({label: itemLabel, content: itemDescription});
    }, [mobile, setShowInfo])

    const getSwiper = useMemo(() => {
        return (
            <div className="swiper-container">
                <Swiper 
                    autoplay={{
                        enabled: autoPlay && images.length > 1,
                        delay: 5000
                    }}
                    className={`slider ${className}`}
                    effect="fade"
                    lazy= {{
                        checkInView: false,
                        enabled: true,
                        loadPrevNext: loadPrevNext,
                    }}
                    loop
                    modules={[ Autoplay, EffectFade, Lazy ]}
                    onSwiper={s => swiper.current = s}
                    onSlideChange={onSlideChange}
                    preloadImages={true}
                    slidesPerView={1}
                    speed={800}
                    runCallbacksOnInit={false}
                    nested={nested}
                >
                    {images.map((image, i) => {
                        return (
                            <SwiperSlide key={i} >
                                <img
                                    alt=""
                                    className={(image.imageCredit || image.imageDesc || image.imageLabel) ? "swiper-lazy has-info" : "swiper-lazy"}
                                    data-sizes={sizes}
                                    data-src={safeImageUrl(image.UrlFragment, image.Default.Width, image.Default.Height, image.Default.Mode, image.Default.QueryString)}
                                    data-srcset={`${image.Alternates.map(x => `${safeImageUrl(image.UrlFragment, x.Width, x.Height, x.Mode, x.QueryString)} ${x.Width}w`).join(', ')}, ${safeImageUrl(image.UrlFragment, image.Default.Width, image.Default.Height, image.Default.Mode, image.Default.QueryString)} ${image.Default.Width}w`}                           
                                />
                                {(image.imageCredit || image.imageDesc || image.imageLabel) ? (
                                    <div className="media-info-bar"  id={`tooltip-${image.UrlFragment}-${i}-parent`}>
                                        {image.imageDesc ? (
                                            <div>
                                                <div
                                                    data-tooltip-id={`tooltip-${image.UrlFragment}-${i}`}
                                                >
                                                    <i className="icon-info" onClick={() => handleInfoClick(image.imageLabel, image.imageDesc)}/>
                                                </div>
                                                <Tooltip className="tooltip-content" id={`tooltip-${image.UrlFragment}-${i}`} place="bottom" content={image.imageDesc} />

                                            </div>
                                        ): null}
                                        <div className='media-info-text' id={`tooltip-${image.UrlFragment}-${i}-container`}>
                                            <>
                                                <p 
                                                    data-tooltip-id={`label-tooltip-${image.UrlFragment}-${i}`}
                                                    id={`label-tooltip-${image.UrlFragment}-${i}-container`}
                                                    className='label-container'
                                                >
                                                    {image.imageLabel}
                                                </p>
                                                {isLongDescription(`tooltip-${image.UrlFragment}-${i}-parent`, `label-tooltip-${image.UrlFragment}-${i}-container`, `credit-${image.UrlFragment}-${i}-container`) ? (
                                                    <Tooltip className="tooltip-content" id={`label-tooltip-${image.UrlFragment}-${i}`} place="bottom" content={image.imageLabel} />
                                                ) : null}

                                            </>
                                            
                                            <p id={`credit-${image.UrlFragment}-${i}-container`}>{image.imageCredit ? `Credit: ${image.imageCredit}` : null}</p>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="swiper-lazy-preloader"></div>
                                
                            </SwiperSlide>
                        )
                    })}
                    
                </Swiper>
                {(showInfo && mobile) ? (
                    <SlideUp header={showInfo.label} onClose={() => setShowInfo(null)} defaultOpen={true}>
                        <p className="slide-up-text">{showInfo.content}</p>
        
                    </SlideUp>
                ) : null}
            </div>
        )
    }, [images, sizes, onSlideChange, loadPrevNext, className, autoPlay, nested, mobile, showInfo, handleInfoClick, isLongDescription])

    return (
        <>
            {showNavigation && thumbsPosition === "top" && <SliderThumbs images={images} active={active} setActive={setActive} />}
            {getSwiper}
            {showNavigation && thumbsPosition === "bottom" && <SliderThumbs images={images} active={active} setActive={setActive} />}
        </>
    )
}

export default SliderComponent