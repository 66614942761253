import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';

import { BasicData } from '@App';
import SpinnerComponent from '@components/spinner';
import SectionContainer from '@containers/section';
import { getBodyData } from '@services/itinerary';
import { getItineraryIdentifier, safeImageUrl, getSliderImages } from '@utils/Utils';
import { Helmet } from 'react-helmet';

const AcommodationComponent = lazy(() => import('@components/accommodation'));
const DailyInfoComponent = lazy(() => import('@components/daily-info'));
const DestinationComponent = lazy(() => import('@components/destination'));

const BodyContainer = () => {
    const { trip_summary } = useContext(BasicData);

    const [bodyData, setBodyData] = useState(null);

    useEffect(() => {
        const itineraryIdentifier = getItineraryIdentifier();

        if (itineraryIdentifier) {
            getBodyData(itineraryIdentifier).then(bodyData => {
                setBodyData(bodyData);
            })
        }
    }, [])

    const getDestinationImage = (destinations) => {
        const firstDestinationId = trip_summary.legs[0]["destination_id"]
        const firstImage = getSliderImages(destinations[firstDestinationId]?.images)[0]
        return safeImageUrl(firstImage?.UrlFragment, firstImage?.Default.Width, firstImage?.Default.Height, firstImage?.Default.Mode, firstImage?.Default.QueryString)
    }

    const getDestinationComponent = (legId, legType, destinationId, startDate, startDay, endDate, endDay, stopIndex) => {
        
        return (
            <SectionContainer className="section destination" id={`destination-${legId}-${destinationId}${stopIndex >= 0 ? `-${stopIndex}` : ''}`}>
                {bodyData ?
                    <Suspense fallback={<SpinnerComponent />}>
                        <Helmet>
                            <meta property='og:image' content={getDestinationImage(bodyData.destinations)} />
                        </Helmet>
                        <DestinationComponent id={destinationId} legType={legType} extendedContent={bodyData.destinations} startDate={startDate} startDay={startDay} endDate={endDate} endDay={endDay} />
                    </Suspense>
                    :
                    <SpinnerComponent />
                }
            </SectionContainer>
        );
    }

    const getAccommodationComponent = (leg, accommodationId) => {
        return (
            <SectionContainer className="section accommodation" id={`accommodation-${leg.id}-${accommodationId}`}>
                {bodyData ?
                    <Suspense fallback={<SpinnerComponent />}>
                        <AcommodationComponent extendedContent={bodyData.accommodations} leg={leg} />
                    </Suspense>
                    :
                    <SpinnerComponent />
                }
            </SectionContainer>
        );
    }

    const getDailyInfoComponent = (legId, accommodationId, destinationId, stopIndex, stopEndDay, stopStartDay, name, lastDayLabel) => {
        return (
            <SectionContainer className="daily-info" id={`daily-info-${legId}${stopIndex >= 0 ? `-${stopIndex}` : ''}`}>
                {bodyData ?
                    <Suspense fallback={<SpinnerComponent />}>
                        <DailyInfoComponent accommodationId={accommodationId} extendedContent={bodyData.leg_periods[legId]} destinationId={destinationId} stopEndDay={stopEndDay} stopIndex={stopIndex} stopStartDay={stopStartDay} name={name} accommodationType={bodyData.accommodations[accommodationId]?.type} lastDayLabel={lastDayLabel} />
                    </Suspense>
                    :
                    <SpinnerComponent />
                }
            </SectionContainer>
        );
    }

    return (
        <div className="body">
            {trip_summary?.legs.map((leg, x) => {
                if (leg.destination_id) {
                    return (
                        <div style={{ position: 'relative' }} key={x}>
                            {getDestinationComponent(leg.id, leg.type, leg.destination_id, leg.start_date, leg.start_day, leg.end_date, leg.end_day)}
                            {leg.accommodation_id && getAccommodationComponent(leg, leg.accommodation_id)}
                            {getDailyInfoComponent(leg.id, leg.accommodation_id, leg.destination_id, undefined, undefined, undefined, undefined, leg.last_day_label)}
                        </div>
                    );
                } else if (leg.stops) {
                    return (
                        leg.stops.map((stop, y) => {
                            return (
                                <div style={{ position: 'relative' }} key={`${x}-${y}`}>
                                    {getDestinationComponent(leg.id, leg.type, stop.destination_id, stop.start_date, stop.start_day, stop.end_date, stop.end_day, y)}
                                    {y === 0 && leg.accommodation_id && getAccommodationComponent(leg, leg.accommodation_id)}
                                    {getDailyInfoComponent(leg.id, leg.accommodation_id, stop.destination_id, y, stop.end_day, stop.start_day, undefined, leg.last_day_label)}
                                </div>
                            );
                        })
                    );
                } else if (leg.accommodation_id) {
                    return (
                        <div style={{ position: 'relative' }} key={x}>
                            {getAccommodationComponent(leg, leg.accommodation_id)}
                            {getDailyInfoComponent(leg.id, leg.accommodation_id, undefined, undefined, undefined, undefined, undefined, leg.last_day_label)}
                        </div>
                    );
                }
                else {
                    return (
                        <div style={{ position: 'relative' }} key={x}>
                            {getDailyInfoComponent(leg.id, undefined, undefined, undefined, undefined, undefined, leg.name, leg.last_day_label)}
                        </div>
                    );
                }
            })}
        </div>
    );
}

export default BodyContainer;