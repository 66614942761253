import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';

import { BasicData } from '@App';
import { useScrollDirection } from '@hooks/useScrollDirection';
import NavBarDropdown from './nav-bar-dropdown';
import NavBarTop from './nav-bar-top';
import NavBarBottom from './nav-bar-bottom';
import NavBarMenu from './nav-bar-menu';
import CoBranding from './co-branding';

import './style.scss';

const NavBarComponent = () => {
    const { theme, branding } = useContext(BasicData);
    const scrollDirection = useScrollDirection();

    const [hide, setHide] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isCover, setIsCover] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleMenu = useCallback(() => {
        if (menuOpen) {
            setDropdownOpen(false);
        }
        setMenuOpen(!menuOpen);
    }, [menuOpen])

    useEffect(() => {
        switch (scrollDirection) {
            case 'down':
                setHide(true);
                setDropdownOpen(false);
                setMenuOpen(false);
                break;
            case 'up':
                setHide(false);
                setDropdownOpen(false);
                setMenuOpen(false);
                break;
            case 'top':
                setHide(false);
                break;
            default:
                break;
        }
    }, [scrollDirection])

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsCover(true);
            } else {
                setIsCover(false);
            }
        }, {
            threshold: 0.1
        })
        const coverPage = document.getElementById('home');
        observer.observe(coverPage);
        return () => {
            observer.disconnect();
        }
    }, [])

    const showCoBranding = useMemo(() => {
        return branding?.is_cobranded;
    }, [branding])

    return (
        <div className={`nav-bar${dropdownOpen ? ' dropdown-open' : ''}${isCover ? ' transparent' : ''}${menuOpen ? ' menu-open' : ''}${hide ? ' hidden' : ''} ${theme?.logo_size ? theme.logo_size.toLowerCase() : 'small'}`}>
            {theme?.logo_size !== "Hide" && <NavBarTop />}
            <NavBarBottom menuOpen={menuOpen} toggleMenu={toggleMenu} />
            <NavBarMenu setDropdownOpen={setDropdownOpen} />
            <NavBarDropdown open={dropdownOpen} setOpen={setDropdownOpen} setMenuOpen={setMenuOpen} />
            {theme?.logo_size !== "Hide" && showCoBranding && <CoBranding navbarHidden={hide} />}
        </div>
    )
}

export default React.memo(NavBarComponent);