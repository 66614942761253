import React, { lazy, Suspense, useContext, useMemo } from 'react';

import { BasicData } from '@App';
import SpinnerComponent from '@components/spinner';

import './style.scss';

const IntroductionComponent = lazy(() => import('./introduction'));
const FastFactsComponent = lazy(() => import('./fast-facts'));
const OverviewComponent = lazy(() => import('./overview'));
const TransportComponent = lazy(() => import('./transport'));
const PricingComponent = lazy(() => import('./pricing'));
const DocumentsComponent = lazy(() => import('./documents'));
const ScheduledDepraturesComponent = lazy(() => import('./scheduled-departures'));
const InteractiveMapWrap = lazy(() => import('@components/interactive-map-wrapper'));


const TripSummaryPage = () => {
    const { documents, summary, price, price_includes, price_excludes, scheduled_departures } = useContext(BasicData);
    const basicData = useContext(BasicData)

    const showPricing = price || price_includes || price_excludes;

    return useMemo(() => (
        <div className="trip-summary">
            {summary && (
                <Suspense fallback={<SpinnerComponent />}>
                    <IntroductionComponent />
                </Suspense>
            )}
            {scheduled_departures && (
                <Suspense fallback={<SpinnerComponent />}>
                    <ScheduledDepraturesComponent />
                </Suspense>
            )}
            <Suspense fallback={<SpinnerComponent />}>
                <FastFactsComponent />
            </Suspense>
            <div id="trip-summary-overview">
                <Suspense fallback={<SpinnerComponent />}>
                    <OverviewComponent />
                </Suspense>
            </div>
            {basicData &&
                <Suspense fallback={<SpinnerComponent />}>
                    <InteractiveMapWrap basicData={basicData} />
                </Suspense>
            }
            <Suspense fallback={<SpinnerComponent />}>
                <TransportComponent />
            </Suspense>
            {showPricing && (
                <Suspense fallback={<SpinnerComponent />}>
                    <PricingComponent />
                </Suspense>
            )}
            {documents && (
                <Suspense fallback={<SpinnerComponent />}>
                    <DocumentsComponent />
                </Suspense>
            )}
        </div>
    ), [summary, documents, showPricing, scheduled_departures, basicData])
}

export default TripSummaryPage;