import { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';

import './style.scss';

const SlideUp = ({ header, headerPrefix, children, className, onClose, defaultOpen, onOpen }) => {
    const [open, setOpen] = useState(defaultOpen || false);
    const [isClosing, setClosing] = useState(false)

    const openContainer = () => {
        setOpen(true);
        if (onOpen) {
            onOpen(true)
        }
        document.querySelector('body').style.overflow = "hidden";
    }

    const closeContainer = () => {
        setClosing(true)
        setTimeout(() => {
            setClosing(false)
            setOpen(false)
            if (onClose) {
                onClose()
                
            }
        }, 0)
        document.querySelector('body').style.overflow = "initial";
        
    }

    useEffect(() => {
        if (open) {
            document.querySelector('body').style.overflow = "hidden";
        }
    }, [open])

    const getClassName = () => {
        if (open && !isClosing) {
            return 'is-open'
        } else if (open && isClosing) {
            return 'is-closed'
        }
    }

    return (
        <>
            {!defaultOpen && (
                children.length
                    ? <div onClick={openContainer}>{children[0]}</div>
                    : <div className={`slide-up-button`} onClick={openContainer}>
                            <h5>{headerPrefix ? `${headerPrefix} ` : ''}<Trans id={header} values={header} /></h5>
                            <i className="icon-chevron-right"></i>
                        </div>
            )}
            <div className={`slide-up-parent${open ? ' open' : ''}`}>
                <div className={`slide-up-container ${getClassName()}${className ? ` ${className}` : ''}`}>
                    <div className="slide-up-header">
                            <i className="icon-x" onClick={closeContainer}></i>
                            <h6><Trans id={header} values={header} /></h6>
                        </div>
                        <div className="slide-up-body">
                            {children.length ? children[1] : children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SlideUp;