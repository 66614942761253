import React from 'react';
import { Trans } from '@lingui/macro';
import { scrollTo } from '@utils/Utils';

import './style.scss';

const NavBarMenu = ({ setDropdownOpen }) => {

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    }

    return (
        <ul className={`nav-bar-menu`}>
            <li><h6 onClick={() => scrollTo('home')}><Trans>Home</Trans></h6></li>
            <li><h6 onClick={() => scrollTo('trip-summary-overview')}><Trans>Trip Summary</Trans></h6></li>
            <li onClick={toggleDropdown} id="btn-toggle-dropdown">
                <h6 style={{ pointerEvents: 'none' }}><Trans>Itinerary</Trans></h6>
                <i style={{ pointerEvents: 'none' }} className='icon icon-chevron-down'></i>
            </li>
        </ul>
    )
}

export default React.memo(NavBarMenu);